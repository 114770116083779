@import 'src/css/globals.scss';
@import 'node_modules/sass-mq/mq';
@import 'node_modules/@au/core/src/css/tools/tools.au-font-size';
@import 'node_modules/@au/core/src/css/tools/tools.au-icon';

.key_input {
  width: 298px;
  @include mq($from: desktop) {
    margin-left: 16px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 298px;
  @include mq($from: desktop) {
    width: 100%;
  }
}

.input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  @include mq($from: desktop) {
    margin-left: 16px;
  }
  > div {
    margin-right: unset;
    width: 100%;
  }
}

.no_margin {
  margin-bottom: unset;
}

.button {
  font-weight: 600;
  width: 125px;
  @include mq($from: desktop) {
    margin-left: 16px;
  }
  &:before {
    @include au-icon("\74");
    padding-right: 8px;
  }
}

.delete {
  border: none;
  background: none;
  outline: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  user-select: none;
  text-align: center;
  padding: 0;
  margin-top: 5px;
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
  @include mq($from: desktop){
    margin-top: unset;
  }
  &, &:hover, &:active, &:visited {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    background-color: $hover;
  }
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &:after {
    @include au-icon("\69");
  }
}

.hint_container {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
  margin-top: 8px;
  @include mq($from: desktop) {
    margin-left: 16px;
  }
}

.icon {
  &:before {
    @include au-icon("\e02a");
    margin-right: 8px;
    font-size: 14px;
    color:#1C96CF;
  }
}